





































































































import PageTitle from 'piramis-base-components/src/components/PageTitle.vue'
import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'
import Icon from 'piramis-base-components/src/components/Icon/Icon.vue'
import ClipboardCopyMixin from 'piramis-base-components/src/logic/helpers/ClipboardCopyMixin'
import { atSignedLogin, tgLoginLink } from 'piramis-base-components/src/shared/utils/tgLoginHelpers'

import { Component, Mixins } from 'vue-property-decorator'

type FlowMasterInfo = {
  avatarLink: string
  name: string
  mail: string
  username: string
  about: string
  tags?: Array<string>
  examples: Array<{title: string, link: string}>
}

@Component({
  data() {
    return {
      tgLoginLink
    }
  },
  components: {
    ConfigField,
    Icon,
    PageTitle
  }
})
export default class FlowMasters extends Mixins(ClipboardCopyMixin) {
  copyText(text: string) {
    this.copy(text)
    this.$t('success_copy')
  }

  users: Array<FlowMasterInfo> = [
    {
      avatarLink: 'https://cdn.presscode.app/flow-masters/alextop35-avatar.jpg',
      name: 'Алексей Белухин',
      username: 'AlexTOP35',
      about: 'Приветствую, друзья! Я - Алексей Белухин, и я занимаюсь созданием сценариев для ботов!<br/>' +
        'Хотите узнать больше о ваших клиентах? Можно разработать бота, который проведет исследование и соберет данные через дружелюбные и легкие вопросы.<br/>' +
        'Опросы помогут вам лучше понять потребности вашей аудитории. Можно создать бота, который умеет собирать и записывать мнение вашей аудитории.<br/>' +
        'Хотите предоставить эксклюзивный контент через вашего бота? Я разработаю сценарий с настройкой доступа к премиум-материалам с легкой оплатой.',
      mail: 'alekseybelukhin@gmail.com',
      tags: [ 'Анкетирование', 'Опросы', 'Платные доступы' ],
      examples: [
        { title: 'Бот для подсчета ответов и выдачи результатов', link: `${ tgLoginLink('KMPRO12_bot') }?start=wezcA1Lv2SesZS6MHcZ7JNcxsX3_cHChsCF1VGDdz14` },
        { title: 'Создание бота-помощника', link: `${ tgLoginLink('KMPRO12_bot') }?start=8JBWvvr6aux74-xOgDBQDdcxsX3_cHChsCF1VGDdz14` },
      ]
    },
    {
      avatarLink: 'https://cdn.presscode.app/flow-masters/uncle_binks_avatar.jpg',
      name: 'Андрей Гостищев',
      username: 'Uncle_Binks',
      about: 'С 2017 года работаю в SMM и успел примерить на себя разные роли: от таргетолога до руководителя SMM-отдела из 4 человек. Вел до 9 проектов одновременно и умею разносторонне подходить к продвижению и разработке продукта. Готов помочь вам сделать бота, который будет выполнять свою работу, а не просто быть "для красоты".',
      mail: 'Unclebinks@yandex.ru',
      tags: [ 'Боты-менеджеры', "Боты-витрины", 'Боты для навигации', 'Мини-онлайн магазины', 'Другие разработки' ],
      examples: [
        { title: 'Бот-менеджер для канала', link: `${ tgLoginLink('gigasimru_bot') }?start=icSYgQz-u-zW5FfYL4exmdcxsX3_cHChsCF1VGDdz14` }
      ]
    },
    {
      avatarLink: 'https://cdn.presscode.app/flow-masters/lovekot-avatar.jpg',
      name: 'Наташа Пе',
      username: 'lovekot',
      about: 'Создание ботов под ключ',
      mail: 'kot.is.love@gmail.com',
      tags: [
        'Продажа товаров, курсов',
        'Подключение платной подписки',
        'Бот для сбора донатов',
        'Бот консультант и подбора продукции',
        'Опросы',
        'Бот для предложки (предложить пост в канал через бота)',
        'Работа со св-ми и ярлыками пользователей в сценарии'
      ],
      examples: [
        { title: 'Бот подбора продукции', link: `${ tgLoginLink('new_presscode_bot') }?start=waNNLYTxM4ni3fSm4-UG6NcxsX3_cHChsCF1VGDdz14` },
        { title: 'Выдача подписки на закрытый канал после оплаты', link: `${ tgLoginLink('new_presscode_bot') }?start=ivboDhFsowuknIsOrGAlONcxsX3_cHChsCF1VGDdz14` },
        { title: 'Выдача подписки после прохождения анкеты', link: `${ tgLoginLink('new_presscode_bot') }?start=qlq1fQnGLq-2OsT2msiTw9cxsX3_cHChsCF1VGDdz14` },
      ]
    },
    {
      avatarLink: 'https://cdn.presscode.app/flow-masters/default-avatar.png',
      name: 'Влад',
      username: 'Vlad_779',
      about: `
      <p>
      Приветствую! Я — опытный разработчик сценариев для ботов, способный реализовать задачи разной сложности.
      </p>

      <p>
      Работаю со всеми возможностями платформы <a target="_blank" href="${ tgLoginLink('Presscodebot') }">${ atSignedLogin('Presscodebot') }</a> . В том числе - с платными подписками, свойствами, ярлыками. Имею опыт разработки сценариев как для индивидуальных заказчиков, так и для крупных компаний.
      </p>

      <p>
      Помимо платформы ${ atSignedLogin('Presscodebot') }, также работаю с <a target="_blank" href="${ tgLoginLink('ChatKeeperBot') }">${ atSignedLogin('ChatKeeperBot') }</a> (профессиональный сервис модерации и статистики от команды @Presscodebot).
      </p>

      <p>
      При заказе сценария в ${ atSignedLogin('Presscodebot') } и настройки ${ atSignedLogin('ChatKeeperBot') } для Вашей группы, готов предоставить скидку в 10%!
      </p>

      Смело пишите по всем вопросам. Мы вместе рассмотрим все варианты реализации вашей идеи!`,
      mail: 'vlad779@internet.ru',
      examples: [
        { title: 'Предложить пост', link: `${ tgLoginLink('ReadySolBot') }?start=YmvMoGbYMmVGLmN5BRG9PdcxsX3_cHChsCF1VGDdz14` },
        { title: 'Доступ в частный канал после анкеты', link: `${ tgLoginLink('ReadySolBot') }?start=iqrOG-ReOPUML3-5_A_6FNcxsX3_cHChsCF1VGDdz14` },
        { title: 'Получить бонус за подписку на канал', link: `${ tgLoginLink('ReadySolBot') }?start=zLILXb9LJk1r6eqH6AlTRdcxsX3_cHChsCF1VGDdz14` },
        { title: 'Опрос на сайте/в боте', link: `${ tgLoginLink('ReadySolBot') }?start=Ufzxqa8SoRewogzetu-gCtcxsX3_cHChsCF1VGDdz14` },
        { title: 'Каталог по категориям', link: `${ tgLoginLink('ReadySolBot') }?start=JzzN7xFKUPPEXWE5bPAD1NcxsX3_cHChsCF1VGDdz14` },
      ]
    },
  ]
}
